<template>
  <main>
    <div class="contact">
      <p>
        Pour toute question<br />
        et/ou réclamation, <br />
        adressez votre demande par email à : <br />
        <a href="mailto:contact@promogest.fr">contact@promogest.fr</a>
        <br />
        <br />
        au plus tard le 31/12/2020 en mentionnant<br />
        « Offre Whaou - Souriez c'est la rentrée ! » <br />
        dans l'objet de votre message.<br /><br />
        Nous vous ferons une réponse sous 72h.
      </p>
    </div>
  </main>
</template>
<script>
export default {
  name: 'Contact',
}
</script>